import { NextPage, NextPageContext } from "next";

import {
  FeedItemResponse,
  FundraiserResponse,
} from "@every.org/common/src/codecs/entities";
import { UserProfileResponse } from "@every.org/common/src/routes/users";

import FundraiserPage from "src/pages/Fundraiser";
import NonprofitsPage from "src/pages/Nonprofit";
import {
  FundraiserInitialProps,
  getSpecialFundraiserInitialProps,
} from "src/utilities/ssr/getFundraiserInitialProps";
import { getNonprofitOrUserInitialProps } from "src/utilities/ssr/getNonprofitOrUserInitialProps";
import { getNonprofitPageInitialProps } from "src/utility/apiClient/nonprofitPage";

type UsernameOrNonprofitPageProps = {
  slug: string;
  userInfo?: UserProfileResponse;
  feedItems?: FeedItemResponse[];
  feedHasMore?: boolean;
  initialFundraisers?: {
    fundraisers: FundraiserResponse[];
    hasMore: boolean;
  };
};

type UsernameOrNonprofitOrFundraiserPageProps =
  | (UsernameOrNonprofitPageProps & { isUsernameOrNonprofitPage: true })
  | (FundraiserInitialProps & { isFundraiserPage: true });

export const getServerSideProps = async (ctx: NextPageContext) => {
  const [fundraiserPageProps, usernameOrNonprofitProps] = await Promise.all([
    await getSpecialFundraiserInitialProps(ctx),
    await getNonprofitOrUserInitialProps(getNonprofitPageInitialProps)(ctx),
  ]);

  if (fundraiserPageProps) {
    if (ctx.res?.statusCode === 404) {
      // If the getNonprofitOrUserInitialProps caused 404 but we did find a fundraiser
      // then overwrite the 404 with 200
      ctx.res.statusCode = 200;
    }

    return {
      props: {
        isFundraiserPage: true,
        ...fundraiserPageProps,
      },
    };
  }

  return {
    props: {
      isUsernameOrNonprofitPage: true,
      ...usernameOrNonprofitProps,
    },
  };
};

const UsernameOrNonprofitOrFundraiserPage: NextPage<
  UsernameOrNonprofitOrFundraiserPageProps
> = (props) => {
  if ("isFundraiserPage" in props) {
    return <FundraiserPage {...props} />;
  }

  const { slug, feedItems, feedHasMore, initialFundraisers } = props;

  return (
    <NonprofitsPage
      slug={slug}
      contributorList={feedItems}
      contributorListHasMore={feedHasMore}
      initialFundraisers={initialFundraisers}
    />
  );
};

export default UsernameOrNonprofitOrFundraiserPage;
